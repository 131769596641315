<template>
  <div class="user-record ly-wrap">
    <drug-nav selected="2"></drug-nav>
    <div class="currency-title">操作记录
      <div class="else-btn" @click="tjcxPOP=true">条件筛选</div>
    </div>

    <div class="record-wrap"
         v-infinite-scroll="loadMore"
         infinite-scroll-disabled="isMoreLoading"
         infinite-scroll-distance="10">
      <div class="rd-item" v-for="item in recordData" :key="item.id">
        <div class="title"><i class="orange" v-if="item.typeCode=='1'">启停泵</i><i class="green"
                                                                                 v-if="item.typeCode=='2'">搅拌模式</i><i
            class="blue" v-if="item.typeCode=='3'">控制模式</i><i v-if="item.typeCode=='4'">启停系统</i><i class="violet"
                                                                                                   v-if="item.typeCode=='5'">参数设置</i><span
            class="user">{{ item.userId }}</span><span>操作时间：{{ item.createTime }}</span>
        </div>
        <div class="name">水厂名称：{{ item.waterId | waterFormat(waterList) }}</div>
        <!--控制模式-->
        <div class="info" v-if="item.typeCode=='3' || item.typeCode=='2'">
          <span class="zh-name">{{ item.typeCode == '3' ? '控制' : '搅拌' }}模式</span>
          <span class="bf">{{ item.dataBefore == '0' ? '自动' : '手动' }}</span>
          <span class="to"> >> </span>
          <span class="af">{{ item.dataNow == '0' ? '自动' : '手动' }}</span>
        </div>
        <div class="info" v-if="item.typeCode=='1' || item.typeCode=='4' || item.typeCode=='5'">
          <span class="zh-name">{{ item.zhName }}</span>
          <span class="bf">{{ item.dataBefore }}</span>
          <span class="to"> >> </span>
          <span class="af">{{ item.dataNow }}</span>
        </div>
      </div>

      <div class="list-tips" v-if="isLoading"> 加载中...</div>
      <div class="list-tips" v-if="noMore">没有更多了~</div>

    </div>


    <ly-foot-tab footOn=""></ly-foot-tab>

    <!--  条件查询  -->
    <mt-popup v-model="tjcxPOP" class="ly-pop-wrap tjcx-wrap" popup-transition="popup-fade">
      <div class="pop-body">
        <mt-checklist
            v-model="query.type"
            :options="options">
        </mt-checklist>
        <div class="time-item">
          <div class="txt">开始日期</div>
          <input class="time-pick" @click="openPicker('start')" type="text"
                 v-model="query.startTime">
        </div>
        <div class="time-item">
          <div class="txt">结束日期</div>
          <input class="time-pick" @click="openPicker('end')" type="text"
                 v-model="query.endTime">
        </div>

      </div>
      <div class="pop-foot">
        <div class="cancel" @click="tjcxPOP = false">取消</div>
        <div class="sure" @click="sureCondition">确定</div>
      </div>
    </mt-popup>
    <mt-datetime-picker
        ref="picker"
        type="date"
        :start-date="start"
        :end-date="end"
        @confirm="handleConfirm"
    >
    </mt-datetime-picker>

  </div>
</template>

<script>
import {getWaterPacLogPage, getWaterWorksList} from '../../api/api.js'
import drugNav from './modules/DrugNav.vue'
import moment from 'moment'
import {getSelectPump} from "../../utils/auth";
import LyFootTab from "../../components/LyFootTab";

export default {
  name: 'PumpAlert',
  components: {drugNav, LyFootTab},
  filters: {
    waterFormat(water, waterList) {
      if (!water) {
        return ''
      }
      for (const item of waterList) {
        const {id, name} = item
        if (id === water) {
          return name
        }
      }
    },
  },
  data() {
    return {
      waterList: [],
      options: [
        {label: '启停泵', value: '1',},
        {label: '搅拌模式', value: '2',},
        {label: '控制模式', value: '3',},
        {label: '启停系统', value: '4',},
        {label: '参数设置', value: '5',},
      ],
      query: {
        startTime: '',
        endTime: '',
        type: [],
        pageNo: 0,
        pageSize: 10,
      },
      start: moment().add(-3, 'years').toDate(),
      end: moment().toDate(),
      tjcxPOP: false,// 条件查询弹框
      recordData: [],
      resultData: {},
      isMoreLoading: false, // 加载更多中
      isLoading: false,
      noMore: false,
      pickType: null,// 时间选择类型
    }
  },
  created() {
    this.loadBase()
  },
  methods: {
    loadBase() {
      getWaterWorksList().then(res => {
        this.waterList = res.data.result
      })
    },
    openPicker(type) {
      this.pickType = type
      this.$refs.picker.open();
    },
    handleConfirm(data) {
      if (this.pickType == 'end') {
        this.query.endTime = moment(data).format('YYYY-MM-DD')
      } else {
        this.query.startTime = moment(data).format('YYYY-MM-DD')
      }
    },
    sureCondition() {
      this.tjcxPOP = false
      this.initData()
    },
    initData() {
      this.query.pageNo = 0
      this.recordData = []
      this.loadData()
    },
    async loadData() {
      this.query.pageNo += 1
      this.selectPump = await getSelectPump()
      const parameter = {
        column: 'createTime',
        field: 'createTime',
        createTime_begin: this.query.startTime,
        createTime_end: this.query.endTime,
        type: this.query.type.join(','),
        pageNo: this.query.pageNo,
        pageSize: this.query.pageSize,
      }
      getWaterPacLogPage(parameter).then(res => {
        this.moreList = res.data.result.records
        this.resultData = res.data.result
        let _this = this
        if (this.moreList.length == 0) {
          this.noMore = true;
          this.isLoading = false;
        } else {
          this.query.pageNo = res.data.result.current
          this.isLoading = false;
          this.moreList.forEach(function (item) {
            _this.recordData.push(item);
          })
        }
      })
    },
    loadMore() {
      this.isMoreLoading = true;
      this.isLoading = true;
      this.noMore = false;
      if (this.query.pageNo >= this.resultData.pages) {
        this.noMore = true;
        this.isLoading = false;
        this.isMoreLoading = false;
      } else {
        this.loadData()
        this.noMore = false;
        this.isMoreLoading = false;
      }
    },
    goBack(path) {
      this.$router.go(-1)
    },
  },
  destroyed: function () {
  },
}
</script>
<style>
.user-record .tjcx-wrap .mint-cell {
  display: inline-block;
}

.user-record .tjcx-wrap .mint-cell-wrapper {
  background: none;
}
</style>
<style scoped lang="less">
.user-record {
  .currency-title {
    .else-btn {
      position: absolute;
      display: inline-block;
      top: 0.12rem;
      right: 0.32rem;
      font-size: 0.28rem;
      width: 1.6rem;
      height: 0.6rem;
      text-align: center;
      line-height: 0.6rem;
      background: rgba(51, 51, 51, 0);
      border: 1px solid #0088FF;
      //box-shadow: 0 0.05rem 0.12rem 0 rgba(0, 136, 255, 0.18);
      border-radius: 10px;
      color: #0088FF;
    }
  }

  .record-wrap {
    padding-bottom: 1.3rem;

    .rd-item {
      padding: 0.3rem 0.2rem;
      height: auto;
      border-bottom: 1px solid #E2E2E2;

      .title {
        display: flex;
        line-height: 0.4rem;
        overflow: hidden;

        i {
          width: 1.04rem;
          height: 0.32rem;
          font-size: 0.2rem;
          font-weight: bold;
          line-height: 0.38rem;
          font-style: normal;
          text-align: center;
          display: inline-block;
          background: #BB8767;
          border-radius: 0.05rem;
          color: #FFFFFF;
        }

        .blue {
          background: #0088FF;
        }

        .orange {
          background: #F2A50C;
        }

        .green {
          background: #00BF00;
        }

        .violet {
          background: #9e3dac;
        }

        span {
          font-size: 0.28rem;
          font-weight: 400;
          color: #999999;
          padding-left: 0.3rem;
        }

        .user {
          flex: 1;
        }
      }

      .name {
        line-height: 0.6rem;
        font-size: 0.28rem;
        font-weight: 400;
        color: #999999;
      }

      .info {
        font-size: 0.36rem;
        font-weight: bold;

        .zh-name {
          color: #333333;
          padding-right: 0.2rem;
        }

        .bf {
          color: #999999;
        }

        .to {
          color: #999999;
        }

        .af {
          color: #333333;
        }

        .tit {
          font-size: 0.28rem;
          line-height: 0.4rem;
          font-weight: 400;
          color: #999999;
        }

        .bf-box {
          font-weight: 400;
          color: #333333;
        }

        .af-box {
          font-weight: bold;
          color: #333333;
        }
      }
    }

    .list-tips {
      line-height: 1rem;
      text-align: center;
      font-size: 0.28rem;
      color: #333333;
    }
  }
}

// 条件筛选弹框
.tjcx-wrap {
  .time-item {
    width: 4.8rem;
    margin: 0 auto;
    text-align: center;
    line-height: 0.8rem;
    font-size: 0.28rem;
    font-weight: 400;
    color: #333333;

    .time-pick {
      width: 4.8rem;
      height: 0.56rem;
      line-height: 0.56rem;
      border-radius: 0.1rem;
      border: 1px solid #0088FF;
      box-shadow: 0 0.05rem 0.12rem 0 rgba(0, 136, 255, 0.18);
    }
  }
}

</style>

